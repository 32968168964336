import "@/styles/main.css";

import "./animations";
import "./scroll-to-anchor";
import "./videos";

import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import ui from "@alpinejs/ui";

// Components
import testimonials from "./components/testimonials";
import members from "./components/members";
import gMap from "./components/g-map";
import award from "./components/award";
import header from "./components/header";
import featuredArticles from "./components/featured-articles";

// Initialize Alpine
window.Alpine = Alpine;

Alpine.plugin(ui);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.data("testimonials", testimonials);
Alpine.data("featuredArticles", featuredArticles);
Alpine.data("members", members);
Alpine.data("header", header);
Alpine.data("map", gMap);
Alpine.data("award", award);
Alpine.start();
